import request from '@/http/request'
import payWay from "@/utils/wayCode";
import channelUserIdUtil from "@/utils/channelUserId";
import config from '@/config'

/** 订单二维码扫码 **/
export function qrCodeScan(data) {
  return request.request({
    url: '/api/scan/qrCode',
    method: 'POST',
    params: data
  })
}

/** 获取url **/
export function getRedirectUrl(payOrderNo, mode) {
  console.log(payWay.getPayWay().wayCode)
  return request.request({
    url: '/api/cashier/redirectUrl/' + payOrderNo,
    method: 'POST',
    params: {
      wayCode: payWay.getPayWay().wayCode,
      mode
    }
  })
}

/** 获取url **/
export function getChannelUserId(payOrderNo , code) {
  let wayCode = payWay.getPayWay().wayCode
  return request.request({
    url: '/api/cashier/channelUserId/' + payOrderNo,
    method: 'POST',
    params: {  wayCode, code  }
  })
}

/** 调起支付接口, 获取订单信息 **/
export function getPayOrderInfo(payOrderNo) {
  return request.request({
    url: '/api/cashier/payOrderInfo/'+ payOrderNo,
    method: 'POST'
  })
}

/** 调起支付接口, 获取支付数据包 **/
export function getPayPackage(payOrderNo,wayCode) {
  return request.request({
    url: '/api/cashier/pay/'+ payOrderNo,
    method: 'POST',
    params: {
      wayCode: wayCode,
      openId: channelUserIdUtil.getChannelUserId()
    }
  })
}



/** 查询支付结果 **/
export function getPayResult(payOrderNo) {
  return request.request({
    url: '/api/cashier/getPayResult',
    method: 'POST',
    params: { payOrderNo }
  })
}

/** 取消订单 **/
export function cancelPay() {
  return request.request({
    url: '/api/cashier/cancelPay',
    method: 'POST',
    data: {
      token: config.cacheToken
    }
  })
}
