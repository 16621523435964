import HttpRequest from '@/http/HttpRequest'

const request = new HttpRequest()
export default request

export const req = {
    // 通用列表查询接口
    list: (url, params) => {
      return request.request({ url: url, method: 'GET', params: params }, true, false, false)
    },
    // 通用分页列表查询接口
    pages: (url, data, params) => {
      return request.request({ url: url + '/pages', method: 'POST', data: data, params: params }, true, false, false)
    },
    // 通用新增接口
    add: (url, data, params) => {
      return request.request({ url: url, method: 'POST', data: data, params: params }, true, true, false)
    },
    // 通用查询单条数据接口
    getById: (url, bizId, params) => {
      return request.request({ url: url + '/' + bizId, method: 'GET', params: params }, true, true, false)
    },
    // 通用修改接口
    updateById: (url, bizId, data, params) => {
      return request.request({ url: url + '/' + bizId, method: 'PUT', data: data, params: params }, true, true, false)
    },
    // 通用更新状态接口
    updateStatus: (url, bizId, params) => {
      return request.request({ url: url + '/updateStatus' + '/' + bizId, method: 'POST', params: params }, true, true, false)
    },
    // 通用删除接口
    delById: (url, bizId, params) => {
      return request.request({ url: url + '/' + bizId, method: 'DELETE', params: params }, true, true, false)
    },
    // 通用批量删除接口
    batchDelByIds: (url, ids) => {
        return request.request({ url: url, method: 'DELETE', data: ids }, true, true, false)
    }
  }
  // 全系列 restful api格式 (全局loading方式)
  export const reqLoad = {
    // 通用列表查询接口
    list: (url, params) => {
      return request.request({ url: url, method: 'GET', params: params }, true, false, true)
    },
    // 通用分页列表查询接口
    pages: (url, data, params) => {
      return request.request({ url: url + '/pages', method: 'POST', data: data, params: params }, true, false, true)
    },
    // 通用新增接口
    add: (url, data, params) => {
      return request.request({ url: url, method: 'POST', data: data, params: params }, true, true, true)
    },
    // 通用查询单条数据接口
    getById: (url, bizId, params) => {
      return request.request({ url: url + '/' + bizId, method: 'GET', params: params }, true, true, true)
    },
    // 通用修改接口
    updateById: (url, bizId, data, params) => {
      return request.request({ url: url + '/' + bizId, method: 'PUT', data: data, params: params }, true, true, true)
    },
    // 通用更新状态接口
    updateStatus: (url, bizId, params) => {
      return request.request({ url: url + '/updateStatus' + '/' + bizId, method: 'POST', params: params }, true, true, true)
    },
    // 通用删除接口
    delById: (url, bizId, params) => {
      return request.request({ url: url + '/' + bizId, method: 'DELETE', params: params }, true, true, true)
    },
    // 通用批量删除接口
    batchDelByIds: (url, ids) => {
        return request.request({ url: url, method: 'DELETE', data: ids }, true, true, true)
    }
  }

/** 获取到webSocket的前缀 （ws://localhost） **/
export function getWebSocketPrefix () {
  // 获取网站域名 +  端口号
  let domain = document.location.protocol + '//' + document.location.host

  // 判断api_base_url 是否设置
  if (process.env.VUE_APP_API_BASE_URL && process.env.VUE_APP_API_BASE_URL !== '/') {
    domain = process.env.VUE_APP_API_BASE_URL
  }

  if (domain.startsWith('https:')) {
    return 'wss://' + domain.replace('https://', '')
  } else {
    return 'ws://' + domain.replace('http://', '')
  }
}
